import React, { useState, useRef, useEffect } from "react";
import logod from "./assets/logod.gif";
import Collections from "@mui/icons-material/Collections";
import Podcasts from "@mui/icons-material/Podcasts";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import InfoIcon from "@mui/icons-material/Info";
import EventIcon from "@mui/icons-material/Event";
import Slide from "@mui/material/Slide";
function Home() {
  const containerRef = React.useRef(null);

  return (
    <div className="home">
      <div className="home-m">
        <div className="box-con"></div>

        <img src={logod} alt="" />

        <Slide
          direction="up"
          in
          timeout={1000}
          container={containerRef.current}
        >
          <div className="flex-bar">
            <div
              onClick={() => {
                window.location.href = "/events";
              }}
              className="button-box"
            >
              <EventIcon fontSize="medium" />
              <div className="btn-text">Events</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "/albums";
              }}
              className="button-box"
            >
              <Collections fontSize="medium" />
              <div className="btn-text">Albums</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "/podcasts";
              }}
              className="button-box"
            >
              <Podcasts fontSize="medium" />
              <div className="btn-text">Podcast</div>
            </div>
            <div className="button-box">
              <VolunteerActivismIcon fontSize="medium" />
              <div className="btn-text">Donate</div>
            </div>
            <div className="button-box">
              <Diversity3Icon fontSize="medium" />
              <div className="btn-text">Members</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "/about";
              }}
              className="button-box"
            >
              <InfoIcon fontSize="medium" />
              <div className="btn-text">About</div>
            </div>
          </div>
        </Slide>
      </div>
      <div className="hback"></div>
    </div>
  );
}
export default Home;
