import React, { useState, useRef, useEffect } from "react";
import logo from "./assets/logo192.png";
import logo1 from "./assets/2.gif";

import Slide from "@mui/material/Slide";

import Footer from "./Footer";
import Header from "./Header";

function Albums() {
  const containerRef = React.useRef(null);

  return (
    <div className="body">
      <Header></Header>
      <div className="scrl">
        <div className="post">
          <div className="item" id="top" ref={containerRef}>
            <div className="back"></div>
            <img src={logo} alt="" />
            <Slide
              direction="up"
              in
              timeout={1000}
              container={containerRef.current}
            >
              <h1>Gallery</h1>
            </Slide>
            <div id="dec-cir1">
              <img src={logo1} alt="" />
            </div>
          </div>
        </div>
        <div className="post">
          <div className="view">
            <div className="pad-20"></div>

            <h1>SRI THUPARAMA PURANA VIHARAYA</h1>
            <h4>Albums</h4>

            <div className="divider-dash"></div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
export default Albums;
