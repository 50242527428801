import React, { useState, useRef, useEffect } from "react";
import logo from "./assets/logo192.png";
import logo1 from "./assets/2.gif";
import bg from "./assets/Untitled-2.png";
import pillar1 from "./assets/pillar1.jpg";
import pillar2 from "./assets/pillar2.jpg";
import bodi from "./assets/bodi.jpg";
import chethiya from "./assets/chethiya.jpg";
import gantara from "./assets/gantara.jpg";

import Slide from "@mui/material/Slide";
import ArticleIcon from "@mui/icons-material/Article";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";

/*Table Comps*/
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "./Footer";
import Header from "./Header";

function About() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData(name, data) {
    return { name, data };
  }

  const rows = [
    createData("Zip Code", "61506, Mudalakkuliya"),
    createData("Sector", "Shayamopali, Malwatta"),
    createData("Generation", "Thalakolahena"),
    createData("Reg.Number", "196552356"),
    createData("Phone", "+94768463836"),
    createData("E-Mail", "admin@thuparamavihara.lk"),
  ];
  const containerRef = React.useRef(null);

  return (
    <div className="body">
      <Header></Header>
      <div className="scrl">
        <div className="post">
          <div className="item" id="top" ref={containerRef}>
            <div className="back"></div>
            <img src={logo} alt="" />
            <Slide
              direction="up"
              in
              timeout={1000}
              container={containerRef.current}
            >
              <h1>About</h1>
            </Slide>
            <div id="dec-cir1">
              <img src={logo1} alt="" />
            </div>
          </div>
        </div>
        <div className="post">
          <div className="view">
            <div className="pad-20"></div>

            <h1>SRI THUPARAMA PURANA VIHARAYA</h1>
            <h4>Preface</h4>
            <p>
              Sri Thuparama Aincient Temple is located 10 km from Anamaduwa town
              in the Puttalam district of the North West Province of Sri Lanka.
              Clear evidence of the founding period of this temple, which has a
              very ancient history, has not been explored so far. The temple has
              a lot of archaeological valuable Tam inscriptions, Tampita temple
              ruins and an ancient crumbling pagoda which clearly explains the
              historical value of the temple.
              <br />
              <div className="flex-wrap-bar">
                <div className="disc-photo">
                  <img src={pillar1} alt="" />
                </div>
                <div className="disc-photo">
                  <img src={pillar2} alt="" />
                </div>
              </div>
              <br />
              This temple, which has a very beautiful environment, is governed
              by the Talakolahena Vihara dynasty and the ruler of this temple is
              the chief of the Talakolahena temple lineage, justice of the peace
              for the entire island, poetry scholar, Most Venerable Kudamithawa
              Gnanarathana Stavira. Moreover, the temple authority is held by
              Polgahawela Parakramabahu National School Teacher, Educationist,
              Venerable Vithikuliye Dhammarathana Thero.
              <br />
              <br />
              There are many ancient scrolls in this temple belonging to the
              ancient royal period. They include ancient formulas and
              prescriptions used in medicine. Also, people from 10 contributing
              villages contribute to this temple and among the rituals performed
              annually in the temple are New Year auspicious rituals, Vesak
              Pujotsavaya, Poson Pujotsavaya, Esala Pinkam Series, Vassana
              Pinkam Series and Katina Pujotsavaa.
              <br />
              <div className="flex-wrap-bar">
                <div className="disc-photo">
                  <img src={chethiya} alt="" />
                </div>
                <div className="disc-photo">
                  <img src={gantara} alt="" />
                </div>
              </div>
              <br />
              Moreover, a Dhamma school named Sri Piyarathana Dhamma School with
              about 120 children is also maintained in this temple. There are 13
              teachers in our Dhamma school, which is popular among many
              students in the area. It is our pure intention to decorate the
              world of children with virtue and religion.
            </p>
            <div className="divider-dash"></div>
            <div className="flex-bar-ver">
              <TableContainer component={Paper}>
                <Table className="table" aria-label="caption table">
                  <caption>
                    All above details are provided according to 2023 data
                    analization.
                  </caption>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        Common details about temple
                      </StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.data}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="divider-dash"></div>
            <div className="flex-bar-hor">
              <a className="button-box" href="#create-ad">
                <AssuredWorkloadIcon fontSize="small" />
                <div className="btn-text">Registration</div>
              </a>
              <a className="button-box" href="#create-ad">
                <ArticleIcon fontSize="small" />
                <div className="btn-text">Constitution</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
export default About;
