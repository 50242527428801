import React from "react";
import gr from "./assets/2.gif";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SupportIcon from "@mui/icons-material/Support";
import InfoIcon from "@mui/icons-material/Info";
import EventIcon from "@mui/icons-material/Event";

const Footer = () => {
  return (
    <div className="footer-bar">
      <a className="go-top" href="#top">
        <KeyboardArrowUpIcon fontSize="large" />
      </a>
      <div className="flex-bar">
        <a href="/events" className="trans-btn">
          <EventIcon></EventIcon>
        </a>
        <a href="/certificates" className="trans-btn">
          <CardMembershipIcon></CardMembershipIcon>
        </a>
        <a href="/application" className="trans-btn">
          <PersonAddIcon></PersonAddIcon>
        </a>
        <a href="/support" className="trans-btn">
          <SupportIcon></SupportIcon>
        </a>
        <a href="/about" className="trans-btn">
          <InfoIcon></InfoIcon>
        </a>
      </div>
      <div className="img">
        <img src={gr} alt="" />
      </div>
      <h4>Sri Thuparama Purana Viharaya</h4>
      <p>
        &copy;2023 <br />
        All Rights Reserved.
      </p>
      <div className="pad-20"></div>
    </div>
  );
};

export default Footer;
