import React from "react";
import TempleBuddhistIcon from "@mui/icons-material/TempleBuddhist";
import Collections from "@mui/icons-material/Collections";
import Podcasts from "@mui/icons-material/Podcasts";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";

function Header() {
  return (
    <header className="App-header">
      <nav className="name">
        <div className="flex-div">
          <a href="/">
            <TempleBuddhistIcon fontSize="medium" />
          </a>
          <a href="/albums">
            <Collections fontSize="medium" />
          </a>
          <a href="/podcasts">
            <Podcasts fontSize="medium" />
          </a>
          <a href="/donations">
            <VolunteerActivismIcon fontSize="medium" />
          </a>
          <a href="/membership">
            <Diversity3Icon fontSize="medium" />
          </a>
        </div>
      </nav>
    </header>
  );
}
export default Header;
