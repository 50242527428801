import React, { useState, useRef, useEffect } from "react";
import logo from "./assets/logo192.png";
import logo1 from "./assets/2.gif";

import Slide from "@mui/material/Slide";
import EventIcon from "@mui/icons-material/Event";
import ArticleIcon from "@mui/icons-material/Article";

/*Table Comps*/
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "./Footer";
import Header from "./Header";

/*calendar*/
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { getTodayDate } from "@mui/x-date-pickers/internals";

function Events() {
  const d = new Date();
  const [value, setValue] = useState(dayjs(d.toLocaleDateString()));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData(name, data) {
    return { name, data };
  }

  const rows = [
    createData("Zip Code", "61506, Mudalakkuliya"),
    createData("Sector", "Shayamopali, Malwatta"),
    createData("Generation", "Thalakolahena"),
    createData("Reg.Number", "196552356"),
    createData("Phone", "+94768463836"),
    createData("E-Mail", "admin@thuparamavihara.lk"),
  ];
  const containerRef = React.useRef(null);

  return (
    <div className="body">
      <Header></Header>
      <div className="scrl">
        <div className="post">
          <div className="item" id="top" ref={containerRef}>
            <div className="back"></div>
            <img src={logo} alt="" />
            <Slide
              direction="up"
              in
              timeout={1000}
              container={containerRef.current}
            >
              <h1>Events</h1>
            </Slide>
            <div id="dec-cir1">
              <img src={logo1} alt="" />
            </div>
          </div>
        </div>
        <div className="post">
          <div className="view">
            <div className="pad-20"></div>
            <h1>SRI THUPARAMA PURANA VIHARAYA</h1>
            <h4>Events Calendar</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateCalendar"]}>
                <DemoItem>
                  <DateCalendar
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
            <div className="input-bar">
              <div className="icon">
                <EventIcon></EventIcon>
              </div>
              <input
                type="text"
                name="date"
                value={value.year() + "-" + value.month() + "-" + value.date()}
                id=""
              />
            </div>
            <div className="input-bar">
              <textarea
                className="textarea"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>

            <div className="flex-bar-hor">
              <a className="button-box" href="#create-ad">
                <ArticleIcon fontSize="small" />
                <div className="btn-text">Request New</div>
              </a>
            </div>
            <div className="divider-dash"></div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
export default Events;
